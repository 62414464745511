import * as React from "react"
import classnames from "classnames";
import * as styles from './index.module.less'

interface CardProps {
  className?: string;
  title?: string;
  bordered?: boolean;
}

const Card: React.FC<CardProps> = (props) => {
  const {
    className,
    title,
    bordered = true,
    children
  } = props;
  const cnames = classnames(styles.Card, 'sd-card', className, {
    [styles.noBorder]: !bordered
  });
  return (
    <div className={cnames}>
      <div className="sd-card--header">
        <h3 className="sd-card--title">
          {title}
        </h3>
      </div>
      <div className="sd-card--body">
        {children}
      </div>
    </div>
  );
}

interface CardGroupProps {
  className?: string;
}
export const CardGroup: React.FC<CardGroupProps> = props => {
  const { className, children } = props;
  const cnames = classnames(className)
  return (
    <div className={cnames}>
      {children}
    </div>
  );
}

export default Card;