import React, { useState, useEffect } from 'react'
import Seo from 'components/seo'
import Layout from 'layouts/zh'
import Card from 'components/Base/Card'
import MediaCard from 'components/Base/MediaCard'
import { UGD } from '../person/index.zh'
import { isPc } from 'utils/utils'
import { Container, FooterContainer } from './index.module.less'

import { fetchUGDLastUEDemo, fetchUGDLastPVDemo } from 'api/user'

import IconBrowse from 'assets/images/account/icon-browse.svg'

const Footprinf: React.FC = (params) => {
  const [demoList, setDemoList] = useState<UGD[]>()
  const [pvDemo, setPVDemo] = useState<UGD[]>()

  useEffect(() => {
    if (isPc()) {
      window.location.href = '/account/person.html'
    }

    // 最近体验demo
    fetchUGDLastUEDemo().then(
      (res) => {
        setDemoList(res.data)
      },
      (e) => {
        console.log(e)
      },
    )
    // 最近浏览的内容
    fetchUGDLastPVDemo().then(
      (res) => {
        setPVDemo(res.data)
      },
      (e) => {
        console.log(e.response)
      },
    )
    if (!isPc()) {
      document.body.className = document.body.className + 'person-mb-FFFFFF'
    }
  }, [])

  return (
    <Layout headerType="black" footerClassName={FooterContainer}>
      <Seo title="我的足迹" description="" keywords="" saTitle=""></Seo>
      <main className={Container}>
        <Card title="最近体验的 Demo">
          {demoList?.map((item, index) => (
            <MediaCard
              icon={item.mainCoverUrl || IconBrowse}
              title={item.title}
              desc={item.description}
              buttonText="继续体验"
              buttonIcon="arrow"
              href={item.infoLink}
              key={index}
            />
          ))}
        </Card>
        <Card title="最近浏览的内容">
          {pvDemo?.slice(0, 2)?.map((item, index) => (
            <MediaCard
              icon={item.mainCoverUrl || IconBrowse}
              iconSize={50}
              desc={item.title}
              buttonText="继续浏览"
              buttonIcon="arrow"
              href={item.infoLink}
              key={index}
            />
          ))}
        </Card>
      </main>
    </Layout>
  )
}

export default Footprinf
