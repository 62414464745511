import React from "react"
import classnames from "classnames";
import './index.module.less'

export enum MediaCardButtonIcon {
  download = 'download',
  arrow = 'arrow'
}

export interface MediaCardParams {
  className?: string | undefined;
  title?: string | undefined;
  desc?: string | undefined;
  icon?: string | undefined;
  thumb?: string | undefined;
  buttonText?: string | undefined;
  buttonIcon?: MediaCardButtonIcon | string | undefined;
  target?: string | undefined;
  href?: string | undefined;
  iconSize?: number | undefined;
  descLineSize?: number | undefined;
  titleLineSize?: number;
}

const MediaCard: React.FC<MediaCardParams> = (params) => {
  const {
    href = ''
  } = params;

  const handleClick = () => {
    window.location.href = href;
  }

  return (
    <div className={classnames('sd-media-card', params.className)} onClick={handleClick}>
      <div className="sd-media-card--left">
        {params.icon && <img src={params.icon} alt="" className="sd-media-card--icon" style={{width: params.iconSize, height: params.iconSize}} />}
        {params.thumb && <img src={params.thumb} alt="" className="sd-media-card--thumb" />}
      </div>
      <div className="sd-media-card--right">
        {params.title && <h3 className={classnames("sd-media-card--title", params.titleLineSize === 2 ? 'line2' : '')}>{params.title}</h3>}
        {params.desc && <div className="sd-media-card--desc"><p className={classnames(params.descLineSize === 2 ? 'line2' : '')}>{params.desc}</p></div>}
        <div className="sd-media-card--button-wrap">
          <a className={classnames("sd-media-card--button", params.buttonIcon)} target={params.target || '_blank'}>{params.buttonText}</a>
        </div>
      </div>
    </div>
  );
}

export default MediaCard;